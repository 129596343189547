import { sortBy } from "lodash";
import Sortable from "sortablejs";

import { BLOCK_TYPE as ANSWERS_BLOCK_TYPE } from "./promptEditorBlock/answersBlock";
import { BLOCK_TYPE as REDIRECT_BLOCK_TYPE } from "./promptEditorBlock/redirectBlock";
import { BLOCK_TYPE as SCENARIO_BLOCK_TYPE } from "./promptEditorBlock/scenarioListBlock";
import { BLOCK_TYPE as SWITCH_BLOCK_TYPE } from "./promptEditorBlock/switchBlock";
import { BLOCK_TYPE as TEXT_BLOCK_TYPE } from "./promptEditorBlock/textBlock";
import { BLOCK_TYPE as TIP_BLOCK_TYPE } from "./promptEditorBlock/tipBlock";

const BLOCK_TYPES = [
    {
        label: "Text",
        value: TEXT_BLOCK_TYPE,
    },
    {
        label: "Suggested topics",
        value: SCENARIO_BLOCK_TYPE,
    },
    {
        label: "Answers",
        value: ANSWERS_BLOCK_TYPE,
    },
    {
        label: "Switch",
        value: SWITCH_BLOCK_TYPE,
    },
    {
        label: "Redirect",
        value: REDIRECT_BLOCK_TYPE,
    },
    {
        label: "Tip",
        value: TIP_BLOCK_TYPE,
    },
];

const DEFAULT_BLOCK_TYPE = BLOCK_TYPES[0].value;

export default function promptEditor() {
    return {
        dragging: false,
        prompt: null,
        collapsed: false,
        id: null,
        dirty: false,
        errors: 0,
        hidden: true,
        form: {
            blockType: DEFAULT_BLOCK_TYPE,
        },

        // TODO: Remove these two lists when we make `promptEditor` an Alpine store.
        variables: [],
        shortcodes: [],

        blockChangeSet: {
            initial: [],
            added: [],
            removed: [],
        },

        promptSortable: null,

        getBlockLabel(block) {
            return BLOCK_TYPES.find((b) => b.value === block).label;
        },

        getBlockTypeOptions() {
            return BLOCK_TYPES;
        },

        removePrompt() {
            this.$dispatch("valence:remove-editor", {
                id: this.id,
                onRemoved: () => {
                    this.prompt.blocks.forEach((block) => block.instance.onRemove());
                    this.$root.remove();
                },
            });
        },

        addBlock() {
            let block = {
                type: this.form.blockType,
                content: "",
                id: this.$id(prompt.role),
                setInstance: function (instance) {
                    this.instance = instance;
                },
                onUpdate: () => {
                    this.checkForChanges();
                },
            };

            this.prompt.blocks = [...this.prompt.blocks, block];
            this.$nextTick(() => {
                this.blockChangeSet.added = [...this.blockChangeSet.added, block.id];
            });
        },

        removeBlock(block) {
            block.instance.onRemove();
            this.prompt.blocks = this.prompt.blocks.filter((b) => b !== block);
            this.$nextTick(() => {
                if (this.blockChangeSet.initial.includes(block.id)) {
                    this.blockChangeSet.removed = [...this.blockChangeSet.removed, block.id];
                } else {
                    this.blockChangeSet.added = this.blockChangeSet.added.filter((id) => id !== block.id);
                }
            });
        },

        buildBlockOrder() {
            const blockIds = this.promptSortable.toArray();
            this.prompt.blocks = sortBy(this.prompt.blocks, (block) => blockIds.indexOf(block.id));
        },

        init() {
            this.promptSortable = new Sortable(this.$refs.blocks, {
                handle: ".handle",
                draggable: ".draggable",
                onBeforeRevert: () => this.buildBlockOrder(),
                onStart: () => (this.dragging = true),
                onEnd: () => (this.dragging = false),
            });

            const promptJSON = this.$root.querySelector('script[type="application/json"]').textContent;
            const payload = JSON.parse(promptJSON);

            const { prompt, is_new: isNew = false, all_variable_names: variables = [], all_shortcode_names: shortcodes = [] } = payload;

            this.hidden = !!prompt.hidden;
            this.variables = variables;
            this.shortcodes = shortcodes;
            this.prompt = prompt;
            this.prompt.blocks = prompt.blocks.map((b) => {
                return {
                    ...b,
                    id: this.$id(prompt.role),
                    setInstance: function (instance) {
                        this.instance = instance;
                    },
                    onUpdate: () => {
                        this.checkForChanges();
                    },
                };
            });

            this.prompt.blocks.forEach((block) => {
                this.blockChangeSet.initial = [...this.blockChangeSet.initial, block.id];
            });

            this.$nextTick(() => {
                this.id = this.$root.id;
                this.$dispatch("valence:register-editor", this);

                this.$watch("hidden", () => {
                    this.checkForChanges();
                });

                this.$watch("blockChangeSet.added", () => {
                    this.checkForChanges();
                });

                this.$watch("blockChangeSet.removed", () => {
                    this.checkForChanges();
                });

                if (isNew) {
                    this.$root.scrollIntoView();
                }
            });

            this.removePrompt.bind(this);
        },

        checkForChanges() {
            if (this.blockChangeSet.added.some((id) => !this.blockChangeSet.initial.includes(id))) {
                this.dirty = true;
            } else if (this.blockChangeSet.removed.some((id) => this.blockChangeSet.initial.includes(id))) {
                this.dirty = true;
            } else if (typeof this.prompt.hidden !== "undefined" && this.hidden !== this.prompt.hidden) {
                this.dirty = true;
            } else {
                this.dirty = this.prompt.blocks.some((block) => block.instance.dirty);
            }
            this.errors = this.prompt.blocks.reduce((accumulator, current) => accumulator + current.instance.errors, 0);
        },

        resetPromptState() {
            this.dirty = false;
            if (typeof this.prompt.hidden !== "undefined") {
                this.prompt.hidden = this.hidden;
            }
            this.prompt.blocks.forEach((block) => block.instance.onSave());
            this.blockChangeSet = {
                added: [],
                removed: [],
                initial: this.prompt.blocks.map((block) => block.id),
            };
        },

        async getPrompt() {
            let prompt = {
                role: this.prompt.role,
                blocks: await Promise.all(
                    this.prompt.blocks.map(async (block) => {
                        return {
                            type: block.type,
                            content: await block.instance.toString(),
                        };
                    }),
                ),
            };

            if (typeof this.prompt.hidden !== "undefined") {
                prompt.hidden = this.hidden;
            }

            return prompt;
        },
    };
}
