import querystring from "query-string";

import { getCookie } from "../utils";

export async function getPromptBlock({ promptId, actionString }) {
    try {
        const query = actionString.split(";")[0].split("?")[1];
        const id = querystring.parse(query).b;

        if (id) {
            const promptBlockId = id;

            const response = await fetch(`/prompts/edit/${promptId}/prompt-block?prompt_block_id=${promptBlockId}`, {
                method: "GET",
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Response not OK");
            }
        } else {
            throw new Error("Block loaded without configuration");
        }
    } catch (error) {
        console.error("Failed to load block", error);
        throw error;
    }
}

export async function savePromptBlock({ promptId, blockType, blockId, configuration }) {
    try {
        const response = await fetch(`/prompts/edit/${promptId}/prompt-block`, {
            method: "POST",
            body: JSON.stringify({
                prompt_block_id: blockId,
                block_type: blockType,
                configuration,
            }),
            headers: {
                "Content-Type": "application/json", // Set content type for JSON data
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error("Response not OK");
        }
    } catch (error) {
        console.error("Failed to save block", error);
        throw error;
    }
}
