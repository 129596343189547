import { getCookie } from "./utils";

export default function profileData(data) {
    return {
        incomingData: data,
        isEditing: false,
        hasData: Boolean(data) && data !== "None", // Check if data is not null, empty, or the string "None"
        profileDataText: data || "",
        init() {},
        saveProfileData(id) {
            this.isEditing = false;
            // post
        },
        deleteProfileData(id) {
            this.profileDataText = "";
            this.hasData = false;
            // delete this is action on profile data itself
        },
        async reviewProfileData(id, open) {
            if (open) {
                try {
                    const response = await fetch(`/accounts/profile-notifications/${id}`, {
                        method: "PUT",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookie("csrftoken"),
                        },
                    });
                } catch (error) {
                    console.error("Failed to get profile notifications", error);
                }

                window.htmx.trigger(document, "valence:profileNotification");
            }
        },
    };
}
