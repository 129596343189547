// Default SortableJS
import Sortable from "sortablejs";

/**
 * A custom plugin that will revert all changes made in the sortablejs plugin.
 * The reason for this is to let Alpine control the list rather than SortableJS.
 * Otherwise, the two will become out of sync and it will result in weird behavior.
 *
 * https://github.com/SortableJS/Sortable/issues/138#issuecomment-1114674135
 */
function RevertPlugin() {
    function Revert() {
        this.defaults = {
            cancelSort: true,
            revertOnSpill: true,
        };
    }

    Revert.prototype = {
        drop({ cancel, dispatchSortableEvent, originalEvent, dragEl, cloneEl }) {
            // Dispatch 'end' event.
            dispatchSortableEvent("beforeRevert");

            // Call revert on spill, to revert the drag
            // using the existing algorithm.
            this.sortable.revertOnSpill.onSpill(...arguments);
            // Undo changes on the drag element.
            if (dragEl) {
                // Remove ghost & chosen class.
                dragEl.classList.remove(this.options.ghostClass);
                dragEl.classList.remove(this.options.chosenClass);
                dragEl.removeAttribute("draggable");
            }
        },
    };

    return Object.assign(Revert, {
        pluginName: "cancelSort",
    });
}

Sortable.mount(RevertPlugin());

export default function promptConfigData(usesDefaultPromptConfiguration, rows, defaultRows, defaultPromptChoiceId, isDefaultOrganization) {
    return {
        rows,
        defaultRows,
        isDefaultOrganization,
        usesDefaultPromptConfiguration: usesDefaultPromptConfiguration,
        defaultRecommendedSortable: [],
        defaultTrendingSortable: [],
        recommendedSortable: null,
        trendingSortable: null,

        init() {
            this.$watch("usesDefaultPromptConfiguration", (result) => {
                if (result !== undefined) {
                    this.usesDefaultPromptConfiguration = result;
                }
                this.buildData();
                this.buildRows();
            });

            if (this.isDefaultOrganization) {
                this.usesDefaultPromptConfiguration = false;
            }

            this.$nextTick(() => {
                this.buildData();
                this.buildRows();
            });
        },
        buildData() {
            this.recommendedSortable = new Sortable(this.$refs.recommended, {
                group: "prompts",
                handle: ".handle",
                draggable: ".draggable",
                onBeforeRevert: () => this.buildRows(),
            });

            this.trendingSortable = new Sortable(this.$refs.trendingTopics, {
                group: "prompts",
                handle: ".handle",
                draggable: ".draggable",
                onBeforeRevert: () => this.buildRows(),
            });
        },
        buildRows() {
            const trending = this.trendingSortable ? this.trendingSortable.toArray() : [];
            const recommended = this.recommendedSortable ? this.recommendedSortable.toArray() : [];

            this.rows = []
                .concat(
                    trending.map((row, i) => {
                        return {
                            ...JSON.parse(row),
                            group: null,
                            priorityScore: trending.length - (i + 1),
                        };
                    }),
                )
                .concat(
                    recommended.map((row, i) => {
                        return {
                            ...JSON.parse(row),
                            group: "recommended",
                            priorityScore: recommended.length - (i + 1),
                        };
                    }),
                );
        },

        get recommendedPrompts() {
            const sourceRows = this.usesDefaultPromptConfiguration ? this.defaultRows : this.rows;
            return sourceRows.filter((row) => row.group === "recommended").sort((a, b) => b.priorityScore - a.priorityScore);
        },

        // Trending tops are any rows that have no group.
        get trendingTopics() {
            const sourceRows = this.usesDefaultPromptConfiguration ? this.defaultRows : this.rows;
            return sourceRows.filter((row) => !row.group).sort((a, b) => b.priorityScore - a.priorityScore);
        },

        addNewRow() {
            this.rows.push({
                id: this.$id("prompt-id"),
                promptId: defaultPromptChoiceId,
                // Priority score should be at the end of Trending Topics since we're adding there first
                priorityScore: this.trendingTopics.length,
                group: null,
            });
        },

        removeRow(row) {
            this.rows = this.rows.filter((r) => r !== row);
            this.$nextTick(() => this.buildRows());
        },
    };
}
