// Matches Tailwind's md: breakpoint, which we use in the navigation UI
const MD_BREAKPOINT_WIDTH = 768;
const isMobileBreakpoint = () => window.innerWidth < MD_BREAKPOINT_WIDTH;
export default {
    isOpen: !isMobileBreakpoint(),
    pane: null,

    init() {
        document.addEventListener("valence:course:open", () => {
            if (isMobileBreakpoint()) {
                this.close();
            }
        });
        document.addEventListener("valence:inertia:visit", () => {
            if (isMobileBreakpoint()) {
                this.close();
            }
        });
    },

    changePane(pane) {
        /* We keep some extra state here as `pane` in order to make the sidebar
         * menu changes feel instant while HTMX returns the contents of the
         * submenu. Alpine controls switching the view between the main menu
         * and the submenu chrome.
         */
        const content = document.getElementById("navigation-pane");
        content.innerHTML = "";

        htmx.trigger(document, "valence:nav:update", { pane: pane.id });
        this.pane = pane;
    },

    clearPane() {
        this.pane = null;
    },

    getPaneTitle() {
        if (!this.pane) {
            return "";
        }

        return this.pane.title;
    },

    toggle() {
        this.isOpen = !this.isOpen;
    },

    open() {
        this.isOpen = true;
    },

    close() {
        if (this.isOpen) {
            this.isOpen = false;
        }
    },
};
