import clonedeep from "lodash.clonedeep";

import { getPromptBlock, savePromptBlock } from "./utils";

export const BLOCK_TYPE = "redirect";
export function redirectBlock() {
    return {
        initialState: {},
        dirty: false,

        promptBlockId: null,
        configuration: {
            url: "",
            label: "",
        },

        async toString() {
            const response = await savePromptBlock({
                promptId: this.promptId,
                blockType: BLOCK_TYPE,
                configuration: this.configuration,
                blockId: this.promptBlockId,
            });

            this.promptBlockId = response.prompt_block_id;
            this.dirty = false;

            if (this.promptBlockId) {
                return `|||---${BLOCK_TYPE}?b=${this.promptBlockId};`;
            }

            return `|||---${BLOCK_TYPE};`;
        },

        onSave() {
            this.dirty = false;
            this.initialState = clonedeep(this.configuration);
        },

        onRemove() {},

        async onInit() {
            if (typeof this.content === "string" && this.content.length > 0) {
                let promptBlock = await getPromptBlock({
                    promptId: this.promptId,
                    actionString: this.content,
                });

                this.configuration = promptBlock.configuration;
                this.initialState = clonedeep(this.configuration);
            }

            this.$watch("configuration", () => {
                this.dirty = this.checkDirty();
                this.onUpdate();
            });
        },

        checkDirty() {
            return this.configuration.url !== this.initialState.url || this.configuration.label !== this.initialState.label;
        },
    };
}
