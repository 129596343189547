/**
 * Used on the organization edit page to manage adding and removing course placements.
 */
export default function courseConfigData(rows, defaultCourseChoiceId) {
    return {
        courseRows: rows,
        addNewCourseRow() {
            this.courseRows.push({
                courseId: defaultCourseChoiceId,
                priorityScore: 0
            });
        },
        removeCourseRow(row) {
            this.courseRows = this.courseRows.filter(r => r !== row);
        }
    }
}
