import { app, authentication } from "@microsoft/teams-js";

async function getClientSideToken() {
    app.initialize();
    try {
        return await authentication.getAuthToken();
    } catch (error) {
        throw new Error("Error getting token: " + error);
    }
}

export default function teamsAuthService() {
    return {
        loading: false,
        async login(ignoreError = false) {
            this.loading = true;
            const token = await getClientSideToken();
            fetch("/microsoft-teams/login/", {
                method: "POST",
                body: JSON.stringify({ token }),
                headers: { "Content-type": "application/json" },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.redirect) {
                        if (ignoreError && !data.success) {
                            this.loading = false;
                            return;
                        }

                        window.location.replace(data.redirect);
                    } else {
                        window.location.href = window.location.href + "?error=Unknown error";
                    }
                });
        },
        async verifyOTP() {
            const token = await getClientSideToken();
            fetch("/microsoft-teams/verify-otp/", {
                method: "POST",
                body: JSON.stringify({ token, otp: document.getElementById("otp").value }),
                headers: { "Content-type": "application/json" },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.redirect) {
                        window.location.replace(data.redirect);
                    } else {
                        window.location.href = window.location.href + "?error=Unknown error";
                    }
                });
        },
    };
}
