export default function copyText() {
    return {
        copied: false,
        async copy(str) {
            await navigator.clipboard.writeText(str);
            this.copied = true;

            setTimeout(() => {
                this.copied = false;
            }, 3000);
        }
    }
}
