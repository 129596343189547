const QUERY_PARAM = "p";

function getFromSearchParams(location, panes) {
    let url = new URL(location.href);
    let pane = url.searchParams.get(QUERY_PARAM);

    if (pane && panes.includes(pane)) {
        return pane;
    } else {
        url.searchParams.delete(QUERY_PARAM);
        history.replaceState(null, document.title, url.toString());
    }

    return null;
}

export default function topnav({ defaultPane, panes }) {
    return {
        init() {
            Alpine.store("navigation").setPane(
                getFromSearchParams(window.location, panes) || defaultPane
            );

            this.$watch("$store.navigation.pane", (newVal) => {
                const url = new URL(window.location.href);
                url.searchParams.set(QUERY_PARAM, newVal);
                history.pushState(null, document.title, url.toString());

                if (Alpine.store("navigation").isOpen) {
                    Alpine.store("navigation").close();
                }
            });

            window.onpopstate = (e) => {
                Alpine.store("navigation").setPane(
                    getFromSearchParams(e.target.location, panes) || defaultPane
                );
            };
        },
    };
}
