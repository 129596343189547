import { toMarkdown } from './utils'

export default function asMarkdown(str) {
    return {
        markdown: "",
        init() {
            this.markdown = toMarkdown(str, {
                inline: false,
            });
        }
    };
}
