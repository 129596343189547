export default function organizationSwitch(organizationSwitches) {
    return {
        rows: organizationSwitches.map((organizationSwitch) => organizationSwitch),
        addNewOrganizationSwitchRow() {
            this.rows.push({ id: "", description: "", promptId: "", enabled: true });
        },
        removeOrganizationSwitchRow(row) {
            this.rows = this.rows.filter((r) => r !== row);
        },
    };
}
