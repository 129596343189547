import { markdown } from "@codemirror/lang-markdown";
import { diagnosticCount, lintGutter } from "@codemirror/lint";
import { EditorState } from "@codemirror/state";
import { basicSetup, EditorView } from "codemirror";
import debounce from "lodash.debounce";

import { getPromptBlock, savePromptBlock } from "./utils";

// Mostly a copy of the text block, with less features.
async function createEditor(doc, element, onUpdate) {
    let editor = new EditorView({
        extensions: [
            EditorView.lineWrapping,
            basicSetup,
            markdown(),
            EditorView.updateListener.of(function (e) {
                onUpdate(e, { diagnosticCount: diagnosticCount(e.state) });
            }),
            lintGutter({}),
        ],
        parent: element,
        doc,
    });

    return editor;
}

export const BLOCK_TYPE = "tip";

export function tipBlock() {
    return {
        initialState: null,
        editor: null,
        dirty: false,

        configuration: {
            content: "",
        },

        async toString() {
            const response = await savePromptBlock({
                promptId: this.promptId,
                blockType: BLOCK_TYPE,
                configuration: {
                    content: this.configuration.content,
                },
                blockId: this.promptBlockId,
            });

            this.promptBlockId = response.prompt_block_id;
            this.dirty = false;

            if (this.promptBlockId) {
                return `|||---tip?b=${this.promptBlockId};`;
            }

            return `|||---tip;`;
        },

        async onInit() {
            if (typeof this.content === "string" && this.content.length > 0) {
                let promptBlock = await getPromptBlock({
                    promptId: this.promptId,
                    actionString: this.content,
                });

                this.configuration = { ...promptBlock.configuration };
                this.initialState = { ...this.configuration };
            }

            this.$watch("configuration", () => {
                this.dirty = this.checkDirty();
                this.onUpdate();
            });

            this.editor = await createEditor(this.configuration.content, this.$refs.blockContent, debounce(this.onChange.bind(this), 200));
        },

        onRemove() {
            this.editor.destroy();
        },

        onSave() {
            this.initialState = EditorState.create({
                doc: this.toString(),
            });
            this.dirty = false;
        },

        onChange(instance) {
            this.configuration.content = instance.state.doc.toString();
            this.dirty = this.checkDirty();
            this.onUpdate();
        },

        checkDirty() {
            return this.initialState.content !== this.configuration.content;
        },
    };
}
