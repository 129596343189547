import "bootstrap-icons/font/bootstrap-icons.css";
import "../../style.css";
import "../css/index.css";
import "./polyfills";

window.addEventListener("htmx:init", async () => {
    await import("htmx.org/dist/ext/loading-states");
    await import("htmx.org/dist/ext/preload");
    Alpine.start();
});

// Admin reporting
import "./userPainDashboard";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import persist from "@alpinejs/persist";
import Alpine from "alpinejs";
import htmx from "htmx.org/dist/htmx";

import adminExperimentRun from "./adminExperimentRun";
import asMarkdown from "./asMarkdown";
import copyText from "./copyText";
import courseConfigData from "./courseConfigData";
import dropdown from "./dropdown";
import emailDomain from "./emailDomain";
import landingForm from "./landing/landingForm";
import localeDate from "./localeDate";
import navigation from "./navigation";
import organizationChatVariable from "./organizationChatVariable";
import organizationSwitch from "./organizationSwitch";
import popularTopics from "./popularTopics";
import profileData from "./profileData";
import profileNotification from "./profileNotification";
import promptBlockEditor from "./promptBlockEditor";
import promptConfigData from "./promptConfigData";
import promptEditor from "./promptEditor";
import promptEditorBlock from "./promptEditorBlock";
import teamsAuthService from "./teamsAuthService";
import topnav from "./topnav";
import typeEffect from "./typeEffect";
import userVariableHelper from "./userVariableHelper";
import personaConfigData from "./workbenchPersonaConfigData";

window.htmx = htmx;
window.Alpine = Alpine;

Alpine.store("navigation", navigation);
Alpine.data("copyText", copyText);
Alpine.data("courseConfigData", courseConfigData);
Alpine.data("dropdown", dropdown);
Alpine.data("asMarkdown", asMarkdown);
Alpine.data("localeDate", localeDate);
Alpine.data("popularTopics", popularTopics);
Alpine.data("promptBlockEditor", promptBlockEditor);
Alpine.data("promptEditorBlock", promptEditorBlock);
Alpine.data("promptEditor", promptEditor);
Alpine.data("typeEffect", typeEffect);
Alpine.data("promptConfigData", promptConfigData);
Alpine.data("userVariableHelper", userVariableHelper);
Alpine.data("emailDomain", emailDomain);
Alpine.data("organizationChatVariable", organizationChatVariable);
Alpine.data("organizationSwitch", organizationSwitch);
Alpine.data("topnav", topnav);
Alpine.data("landingForm", landingForm);
Alpine.data("adminExperimentRun", adminExperimentRun);
Alpine.data("personaConfigData", personaConfigData);
Alpine.data("teamsAuthService", teamsAuthService);
Alpine.data("profileNotification", profileNotification);
Alpine.data("profileData", profileData);

Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(focus);

window.dispatchEvent(new Event("htmx:init"));

// Dark mode in Daisy does bad things to our site - force everyone to light mode for now
document.documentElement.dataset.theme = "light";
localStorage.setItem("theme", "light");
