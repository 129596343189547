const WORDS_AT_TIME = [1, 2, 3, 4, 5, 6];
let getAmountToPrint = () => WORDS_AT_TIME[Math.floor(Math.random() * WORDS_AT_TIME.length)]

export default function typeEffect({ submitForm, enabled = true } = { submitForm: false }) {
    return {
        str: "",
        strLen: 0,
        exploded: [],
        element: null,
        content: null,
        skip: null,
        currentIndex: 0,
        interval: null,
        enabled,

        showContent() {
            window.clearInterval(this.interval);
            this.interval = null;

            this.element.innerHTML = this.str;

            if (this.skip) {
                this.skip.classList.add('hidden');
            }

            if (this.content) {
                this.content.classList.replace('hidden', 'block')
            }

            if (submitForm && this.$refs.form) {
                setTimeout(() => {
                    this.$refs.form.submit();
                }, 3000)
            }
        },

        startTyping() {
            let amount = getAmountToPrint()

            if (this.currentIndex < this.strLen) {
                let sequence = this.exploded.slice(this.currentIndex, this.currentIndex + amount).join(" ");
                this.element.textContent += sequence + " ";

                this.currentIndex = this.currentIndex + amount;
                this.interval = setTimeout(() => this.startTyping(), 200);
            } else {
                this.showContent()
            }
        },

        init() {
            this.element = this.$refs.text;
            this.skip = this.$refs.skip;
            this.content = this.$refs.content;
            this.str = this.element.textContent;
            this.exploded = this.str.split(" ");
            this.strLen = this.exploded.length;

            if (enabled) {
                this.element.innerHTML = "";
                this.element.classList.replace('hidden', 'flex');

                this.interval = setTimeout(() => this.startTyping(), 300)
            }

        }
    }
}
