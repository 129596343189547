import clonedeep from "lodash.clonedeep";

import { getPromptBlock, savePromptBlock } from "./utils";

export const BLOCK_TYPE = "scenario-list";
export function scenarioListBlock() {
    return {
        promptOptions: [],
        initialState: {},
        dirty: false,
        configuration: {
            prompts: [],
            includeSuggestedTopics: false,
        },
        selectedPromptIds: [],

        updateSelectedPromptIds() {
            this.selectedPromptIds = this.configuration.prompts.map((p) => Number(p.promptId));
        },

        async toString() {
            const response = await savePromptBlock({
                promptId: this.promptId,
                blockId: this.promptBlockId,
                blockType: BLOCK_TYPE,
                configuration: {
                    prompts: this.configuration.prompts.map((i) => ({
                        prompt_id: Number(i.promptId),
                        title: i.title ? i.title : null,
                        description: i.description ? i.description : null,
                        estimated_time: i.estimatedTime ? Number(i.estimatedTime) : null,
                    })),
                    includeSuggestedTopics: this.configuration.includeSuggestedTopics,
                },
            });

            this.promptBlockId = response.prompt_block_id;
            this.dirty = false;

            if (this.promptBlockId) {
                return `|||---scenarios?b=${this.promptBlockId};`;
            }

            return "|||---scenarios;";
        },

        onSave() {
            this.dirty = false;
            this.initialState = clonedeep(this.configuration);
        },

        onRemove() {},

        checkDirty() {
            const loaded = this.initialState.prompts.map((s) => s.id);
            const current = this.configuration.prompts.map((s) => s.id);

            if (current.some((s) => loaded.indexOf(s) === -1)) {
                // Dirty if there is at least one scenario that was not in the initially loaded list
                return true;
            }

            // Dirty if at least one of the initially loaded scenarios has been removed
            if (loaded.some((s) => current.indexOf(s) === -1)) {
                return true;
            }

            return this.initialState.prompts.some((p) => {
                const prompt = this.configuration.prompts.find((i) => p.id === i.id);

                if (!prompt) {
                    return false;
                }

                return prompt.promptId !== p.promptId || prompt.title !== p.title || prompt.estimatedTime !== p.estimatedTime || prompt.description !== p.description;
            });
        },

        addPrompt() {
            this.configuration = {
                ...this.configuration,
                prompts: [
                    ...this.configuration.prompts,
                    {
                        id: Symbol(),
                        title: undefined,
                        estimatedTime: undefined,
                        promptId: undefined,
                        description: undefined,
                    },
                ],
            };
        },

        removePrompt(prompt) {
            this.configuration = {
                ...this.configuration,
                prompts: this.configuration.prompts.filter((p) => p.id !== prompt.id),
            };
            this.dirty = this.checkDirty();
            this.onUpdate();
        },

        async onInit() {
            if (typeof this.content === "string" && this.content.length > 0) {
                const promptBlock = await getPromptBlock({
                    promptId: this.promptId,
                    actionString: this.content,
                });

                this.configuration = {
                    ...promptBlock.configuration,
                    prompts: promptBlock.configuration.prompts.map((i) => ({
                        id: Symbol(),
                        title: i.title,
                        description: i.description,
                        promptId: i.prompt_id,
                        estimatedTime: i.estimated_time,
                    })),
                };
                this.initialState = clonedeep(this.configuration);
            }

            const response = await fetch("/prompts/prompt-editor/prompts", {
                method: "GET",
            });

            if (response.ok) {
                const data = await response.json();
                this.promptOptions = data.prompts;
            }

            this.$watch("configuration", () => {
                this.updateSelectedPromptIds();
                this.dirty = this.checkDirty();
                this.onUpdate();
            });

            this.updateSelectedPromptIds();
        },
    };
}
