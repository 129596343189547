export default function organizationChatVariable(organizationChatVariables) {
    return {
        rows: organizationChatVariables.map((organizationChatVariable) => organizationChatVariable),
        addNewOrganizationChatVariableRow() {
            this.rows.push({ key: "", value: "" });
        },
        removeOrganizationChatVariableRow(row) {
            this.rows = this.rows.filter((r) => r !== row);
        }
    };
}
