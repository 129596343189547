/**
 * Used on the workbench edit page to manage adding and removing personas.
 */
export default function personaConfigData(rows) {
    return {
        personaRows: rows,
        addNewPersonaRow(personaId) {
            this.personaRows.push({personaId: personaId, name: name});
        },
        removePersonaRow(row) {
            this.personaRows = this.personaRows.filter(r => r !== row);
        }
    }
}
