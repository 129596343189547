export default function localeDate(time) {
    let locale = navigator.language || 'en-US';
    let dateFormat = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    return {
        timestamp: dateFormat.format(new Date(time))
    }
}
