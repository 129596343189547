import { savePromptBlock, getPromptBlock } from "./utils";
import clonedeep from "lodash.clonedeep";

export const BLOCK_TYPE = "answers";
export function answersBlock() {
    return {
        initialState: {},
        dirty: false,

        promptBlockId: null,
        configuration: {
            answers: [],
            saveUserVariable: false,
            userVariable: null,
            userVariableDefault: null,
        },

        async toString() {
            const response = await savePromptBlock({
                promptId: this.promptId,
                blockType: BLOCK_TYPE,
                configuration: {
                    save_user_variable:
                        this.configuration.saveUserVariable,
                    user_variable: this.configuration.saveUserVariable
                        ? this.configuration.userVariable
                        : null,
                    user_variable_default: this.configuration.saveUserVariable
                    ? this.configuration.userVariableDefault
                    : null,
                    // Removes the client-side only `id`
                    answers: this.configuration.answers.map((a) => ({
                        value: a.value,
                        save_hidden_message: a.saveHiddenMessage,
                        hidden_message: a.hiddenMessage,
                    })),
                },
                blockId: this.promptBlockId,
            });

            this.promptBlockId = response.prompt_block_id;
            this.dirty = false;

            if (this.promptBlockId) {
                return `|||---answers?b=${this.promptBlockId};`;
            }

            return `|||---answers;`;
        },

        onSave() {
            this.dirty = false;
            this.initialState = clonedeep(this.configuration);
        },

        onRemove() {},

        async onInit() {
            if (typeof this.content === "string" && this.content.length > 0) {
                let promptBlock = await getPromptBlock({
                    promptId: this.promptId,
                    actionString: this.content,
                });

                this.configuration = {
                    ...promptBlock.configuration,
                    saveUserVariable: Boolean(
                        promptBlock.configuration.save_user_variable
                    ),
                    userVariable: promptBlock.configuration.user_variable,
                    userVariableDefault:
                        promptBlock.configuration.user_variable_default,
                    answers: promptBlock.configuration.answers.map((i) => ({
                        // We add a Symbol for easier change-set tracking to determine
                        // if the block is "dirty" and should show the "not saved" indicator.
                        id: Symbol(),
                        value: i.value,
                        saveHiddenMessage: i.save_hidden_message,
                        hiddenMessage: i.hidden_message,
                        ...i,
                    })),
                };
                this.initialState = clonedeep(this.configuration);
            }

            this.$watch("configuration", () => {
                this.dirty = this.checkDirty();
                this.onUpdate();
            });
        },

        checkDirty() {
            let loaded = this.initialState.answers.map((a) => a.id);
            let current = this.configuration.answers.map((a) => a.id);

            if (current.some((s) => loaded.indexOf(s) === -1)) {
                // Dirty if there is at least one answer that was not in the initially loaded list
                return true;
            }

            // Dirty if at least one of the initially loaded answers has been removed
            if (loaded.some((s) => current.indexOf(s) === -1)) {
                return true;
            }

            // Dirty if user variable has been selected
            if (
                this.initialState.saveUserVariable !==
                this.configuration.saveUserVariable
            ) {
                return true;
            }

            // Dirty if user variable has been set or changed
            if (
                this.initialState.userVariable !==
                this.configuration.userVariable
            ) {
                return true;
            }

            return this.initialState.answers.some((i) => {
                let answer = this.configuration.answers.find(
                    (a) => a.id === i.id
                );

                if (!answer) {
                    return false;
                }

                return (
                    answer.value !== i.value ||
                    answer.saveHiddenMessage !== i.saveHiddenMessage ||
                    answer.hiddenMessage !== i.hiddenMessage
                );
            });
        },

        removeAnswer(answer) {
            this.configuration = {
                ...this.configuration,
                answers: this.configuration.answers.filter(
                    (a) => a.id !== answer.id
                ),
            };

            this.dirty = this.checkDirty();
            this.onUpdate();
        },

        addAnswer() {
            this.configuration = {
                ...this.configuration,
                answers: [
                    ...this.configuration.answers,
                    {
                        value: "",
                        id: Symbol(),
                        saveHiddenMessage: false,
                        hiddenMessage: "",
                    },
                ],
            };
            this.dirty = this.checkDirty();
            this.onUpdate();
        },
    };
}
