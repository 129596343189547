import Chart from "chart.js/auto";

(async function slowMessagesChart() {
    const slowMessagesElement = document.getElementById("slow-messages");

    if (!slowMessagesElement) {
        return;
    }

    const data = JSON.parse(document.getElementById("slow-messages-data").textContent);

    new Chart(slowMessagesElement, {
        type: "line",
        options: {
            tension: 0.3,
            scales: {
                x: { title: { text: "Seconds", display: true } },
                y: { title: { text: "Number of Users", display: true } },
            },
        },
        data: {
            labels: data.map((row) => row.seconds),
            datasets: [
                {
                    label: "Number of users",
                    data: data.map((row) => row.num),
                },
            ],
        },
    });
})();

(async function leftMessagesChart() {
    const leftMessagesElement = document.getElementById("left-messages");

    if (!leftMessagesElement) {
        return;
    }

    const data = JSON.parse(document.getElementById("left-messages-data").textContent);

    new Chart(leftMessagesElement, {
        type: "line",
        options: {
            tension: 0.3,
            scales: {
                x: { title: { text: "Seconds", display: true } },
                y: { title: { text: "Number of Users", display: true } },
            },
        },
        data: {
            labels: data.map((row) => row.seconds),
            datasets: [
                {
                    label: "Number of users",
                    data: data.map((row) => row.num),
                },
            ],
        },
    });
})();

(async function experiencesByDateChart() {
    const experiencesByDateElement = document.getElementById("experiences-by-date");

    if (!experiencesByDateElement) {
        return;
    }

    const data = JSON.parse(document.getElementById("experiences-by-date-data").textContent);

    new Chart(experiencesByDateElement, {
        type: "line",
        options: {
            scales: {
                y: { stacked: true, min: 0, max: 100, title: { text: "Percent of total experiences", display: true } },
                x: { title: { text: "Date", display: true } },
            },
            tension: 0.3,
        },
        data: {
            labels: data.map((row) => row.date),
            datasets: [
                {
                    label: "% good experiences",
                    data: data.map((row) => row.pct_good),
                    fill: true,
                    backgroundColor: "#0ba739",
                },
                {
                    label: "% slow messages or transcription",
                    data: data.map((row) => row.pct_slow),
                    fill: true,
                    backgroundColor: "#ff9a00",
                },
                {
                    label: "% no messages or transcription",
                    data: data.map((row) => row.pct_no),
                    fill: true,
                    backgroundColor: "#de173d",
                },
            ],
        },
    });
})();

(async function exchangesByDateChart() {
    const exchangesByDateElement = document.getElementById("exchanges-by-date");

    if (!exchangesByDateElement) {
        return;
    }

    const data = JSON.parse(document.getElementById("experiences-by-date-data").textContent);

    new Chart(exchangesByDateElement, {
        type: "bar",
        options: {
            scales: {
                y: { title: { text: "Number of exchanges / users", display: true } },
                x: { title: { text: "Date", display: true }, stacked: true },
            },
            tension: 0.3,
        },
        data: {
            labels: data.map((row) => row.date),
            datasets: [
                {
                    label: "number of users",
                    data: data.map((row) => row.num_users),
                    fill: true,
                },
                {
                    label: "number of exchanges",
                    data: data.map((row) => row.total),
                    fill: true,
                },
            ],
        },
    });
})();
