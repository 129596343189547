export default function emailDomain(domains) {
    return {
        rows: domains.map((domain) => ({ domain })),
        addNewRow() {
            this.rows.push({ domain: "" });
        },
        removeRow(row) {
            this.rows = this.rows.filter((r) => r !== row);
        },
    };
}
