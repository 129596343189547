import { EditorState } from "@codemirror/state";
import debounce from "lodash.debounce";
import { getVariableLinter } from "../editorLinting";
import { basicSetup, EditorView } from "codemirror";
import { markdown } from "@codemirror/lang-markdown";
import { autocompletion } from "@codemirror/autocomplete";
import { diagnosticCount, lintGutter } from "@codemirror/lint";

async function createEditor(doc, element, onUpdate, variables, shortcodes) {
    let variableLinter = await getVariableLinter({ variables, shortcodes });
    function completions(context) {
        let before = context.matchBefore(/\w+/);
        if (!context.explicit && !before) return null;

        const options = variables.map((v) => {
            return {
                label: `{{ ${v} }}`,
                type: "variable",
                info: `Description of ${v}`,
            };
        });

        return {
            from: before ? before.from : context.pos,
            options: options,
            validFor: /^\w*$/,
        };
    }

    let editor = new EditorView({
        extensions: [
            EditorView.lineWrapping,
            basicSetup,
            markdown(),
            autocompletion({ override: [completions] }),
            EditorView.updateListener.of(function (e) {
                onUpdate(e, { diagnosticCount: diagnosticCount(e.state) });
            }),
            variableLinter,
            lintGutter({}),
        ],
        parent: element,
        doc,
    });

    return editor;
}

export const BLOCK_TYPE = "text";
export function textBlock() {
    return {
        initialState: null,
        editor: null,

        async onInit() {
            this.initialState = EditorState.create({ doc: this.content });
            this.editor = await createEditor(
                this.content,
                this.$refs.blockContent,
                debounce(this.onChange.bind(this), 200),
                this.variables,
                this.shortcodes
            );
        },

        onRemove() {
            this.editor.destroy();
        },

        onSave() {
            this.initialState = EditorState.create({
                doc: this.toString(),
            });
            this.dirty = false;
        },

        toString() {
            if (this.editor) {
                return this.editor.state.doc.toString();
            }

            return "";
        },

        onChange(instance, { diagnosticCount }) {
            this.dirty = !instance.state.doc.eq(this.initialState.doc);
            this.errors = diagnosticCount;
            this.onUpdate();
        },
    };
}
