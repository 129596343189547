import clonedeep from "lodash.clonedeep";

export const BLOCK_TYPE = "switch";

export function switchBlock() {
    return {
        initialState: {},
        dirty: false,
        promptOptions: [],
        configuration: {
            script: ''
        },

        async toString() {
            return `|||---${BLOCK_TYPE}?script=${this.configuration.script};`;
        },

        onSave() {
            this.dirty = false;
            this.initialState = clonedeep(this.configuration);
        },

        onInit() {
            this.setPromptOptions();

            if (typeof this.content !== "string" || this.content.length == 0) {
                return;
            }

            const rule = /^\|\|\|---switch\?script=(.+);$/i;
            const match = rule.exec(this.content);

            if (!match) {
                return;
            }

            const script = match[1];
            this.configuration = { script };
            this.initialState = clonedeep(this.configuration);
        },

        checkDirty() {
            return (
                this.configuration.script !== this.initialState.script
            );
        },

        onRemove() {},

        async setPromptOptions() {
            const response = await fetch("/prompts/prompt-editor/prompts", {
                method: "GET",
            });

            if (!response.ok) {
                return;
            }

            const data = await response.json();
            this.promptOptions = data.prompts;
        }
    };
}
