export default function landingForm(email = "") {
    return {
        email,
        enableSSO: false,
        showSSO: false,
        get loginWithSSO() {
            return this.showSSO && this.enableSSO;
        },
        get ssoButtonText() {
            return !this.loginWithSSO ? 'Sign in with SSO' : 'Go back';
        },
        toggleShowSSO() {
            if(this.showSSO) {
                this.showSSO = false;
                this.$nextTick(() => this.$refs.email.focus());
                return;
            }

            this.showSSO = true;
            this.$nextTick(() => this.$refs.ssoEmail.focus());
        },
        init() {
            const params = new URLSearchParams(window.location.search);
            this.enableSSO = params.get("sso") === '1';
        },
    };
}
