import { getCookie } from "./utils";

export default function profileNotification() {
    return {
        notificationCount: 0, // Initialize with a default value
        showBadge: false,
        init() {
            document.addEventListener("valence:profileNotification", () => {
                // potentially add a time before executing here
                this.getProfileNotifications().then((count) => {
                    this.notificationCount = count;
                    this.showBadge = count > 0;
                });
            });

            // Run once on the initial page load
            this.getProfileNotifications().then((count) => {
                this.notificationCount = count;
                this.showBadge = count > 0;
            });
        },
        async getProfileNotifications() {
            try {
                const response = await fetch("/accounts/profile-notifications/", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                });
                const data = await response.json();
                return data.notification_count || 0; // Adjust based on your API response structure
            } catch (error) {
                console.error("Failed to get profile notifications", error);
                return 0; // Return 0 in case of an error
            }
        },
        async markProfileNotificationRead(key) {
            // amplitude deleting the notificaitons here
        },
        async deleteInferredAnswer() {},
    };
}
