import { textBlock, BLOCK_TYPE as TEXT_BLOCK_TYPE } from "./textBlock";
import {
    scenarioListBlock,
    BLOCK_TYPE as SCENARIO_BLOCK_TYPE,
} from "./scenarioListBlock";
import { answersBlock, BLOCK_TYPE as ANSWERS_BLOCK_TYPE } from "./answersBlock";
import {
    redirectBlock,
    BLOCK_TYPE as REDIRECT_BLOCK_TYPE,
} from "./redirectBlock";
import { switchBlock, BLOCK_TYPE as SWITCH_BLOCK_TYPE } from "./switchBlock";
import { tipBlock, BLOCK_TYPE as TIP_BLOCK_TYPE } from "./tipBlock";

export default function promptEditorBlock({
    block,
    variables,
    shortcodes,
    promptId,
}) {
    let instance = {};
    let baseBlock = {
        content: block.content,
        type: block.type,
        id: Symbol(),
        dirty: false,
        errors: 0,
        promptId,
        variables,
        shortcodes,

        onUpdate() {
            block.onUpdate();
        },

        init() {
            this.$nextTick(() => {
                block.setInstance(this);
                this.onInit();
            });
        },
    };

    switch (block.type) {
        case TEXT_BLOCK_TYPE:
            instance = textBlock();
            break;
        case SCENARIO_BLOCK_TYPE:
            instance = scenarioListBlock();
            break;
        case ANSWERS_BLOCK_TYPE:
            instance = answersBlock();
            break;
        case REDIRECT_BLOCK_TYPE:
            instance = redirectBlock();
            break;
        case SWITCH_BLOCK_TYPE:
            instance = switchBlock();
            break;
        case TIP_BLOCK_TYPE:
            instance = tipBlock();
            break;
        default:
            throw new Error(`Unknown block of type ` + block.type);
    }

    /* Alpine's reactivity system works with simple object literals. Unfortunately,
     * using classes or prototype to define a base "block" does not work well. As such,
     * here we create the object by merging the "base block" and the block type instance,
     * which contains the implementation and state that is specific to each kind of block.
     */
    return {
        ...baseBlock,
        ...instance,
    };
}
