import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
import "@glidejs/glide/dist/css/glide.core.css";

export default function popularTopics(numTopics) {
    return {
        numTopics,

        init() {
            this.$nextTick(() => {
                new Glide(this.$refs.glide, {
                    type: 'slider',
                    perView: 2,
                    startAt: 0,
                    breakpoints: {
                        1024: {
                            perView: 1,
                        }
                    }
                }).mount({ Controls, Breakpoints });
            });
        }
    };
}
