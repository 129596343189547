export default function userVariableHelper({key, label}) {
    return {
        ifClause: '',
        elseClause: '',
        result: '',
        copied: false,
        placeholder: `{{ ${label} }}`,

        generate() {
            return`{% if blank(${label}) %}
${this.ifClause}
{% else %}
${this.elseClause}
{% endif %}
`
        },

        async copy() {
            let str = this.generate();
            await navigator.clipboard.writeText(str);
            this.copied = true;

            setTimeout(() => {
                this.copied = false;
            }, 3000);
        }
    }
}
