import TextLintMarkdownPlugin from "@textlint/textlint-plugin-markdown";
import { linter } from "@codemirror/lint";

const VARIABLES_REGEX = /{{\s*(.*?)\s*}}/g;
const SHORTCODES_REGEX = /\|\|\|---\w+;/g;

let textLintKernelModule;
let getTextLintKernelModule = async () => {
    if (textLintKernelModule) {
        return textLintKernelModule;
    }

    textLintKernelModule = await import("@textlint/kernel");
    return textLintKernelModule;
};

function variableLintRule(context, { variables, shortcodes }) {
    const { Syntax, report } = context;
    return {
        [Syntax.Str](node) {
            const text = context.getSource(node);
            const variable_matches = [...text.matchAll(VARIABLES_REGEX)].map((s) =>
                s[1].trim()
            );
            const shortcode_matches = text.matchAll(SHORTCODES_REGEX);

            for (let match of variable_matches) {
                if (variables.indexOf(match) === -1) {
                    report(
                        node,
                        new Error(`Variable "${match}" does not exist.`)
                    );
                }
            }

            for (let match of shortcode_matches) {
                if (shortcodes.indexOf(match[0]) === -1) {
                    report(
                        node,
                        new Error(`Action "${match}" does not exist.`)
                    )
                }
            }
        },
    };
}

export async function getVariableLinter({ variables, shortcodes }) {
    let { TextlintKernel } = await getTextLintKernelModule();

    const config = {
        // Just setting the string, doesn't actually do anything.
        filePath: "/path/to/file.md",
        rules: [
            {
                ruleId: "invalid-variable",
                rule: variableLintRule,
                options: {
                    variables,
                    shortcodes
                },
            },
        ],
        plugins: [
            {
                pluginId: "markdown",
                plugin: TextLintMarkdownPlugin,
            },
        ],
        ext: ".md",
    };

    async function lintMarkdown(view) {
        let diagnostics = [];
        let content = view.state.doc.toString();
        let kernel = new TextlintKernel();
        let lintingResults = await kernel.lintText(content, config);

        for (let result of lintingResults.messages) {
            diagnostics.push({
                from: result.range[0],
                to: result.range[1],
                severity: "error",
                message: result.message,
            });
        }

        return diagnostics;
    }

    return linter(lintMarkdown);
}
